import React, { useState, useEffect, useRef } from "react";
import MaterialTable from "material-table";
import { apipaths } from "../../../api/apiPaths";
import { getResponse } from "../../../api/apiResponse";
import $ from "jquery";
import { Tooltip } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import FilterComponent from "../reusableComponents/filters";
import "./style.css";
import { toast } from "react-toastify";
import { Modal } from "antd";
import Select from "react-select";
import TextEditor from "../../TextEditor";
import HardwareList from "../hardwareList";

function SoftwareList() {
  const [inventories, setInventories] = useState([]);
  const [isButtonDisabled, disableButton] = useState(true);
  const [softwareList, setSoftwareList] = useState([]);
  const [modal, setModal] = useState(false);
  const [editFormData, setEditFormData] = useState({});
  const [inventoryId, setInventoryId] = useState("");
  const [formData, setFormdata] = useState({
    name: "",
    model: "",
    version: "",
  });
  const [editSoftwareModal, setEditSoftwareModal] = useState(false);
  const [editSoftwareId, setEditSoftwareId] = useState();
  const [softwareId, setSoftwareId] = useState("");
  const [editSoftware, setEditSoftware] = useState({
    name: "",
    assigned_to: null,
    expiry_date: "",
    version: "",
  });
  const [assignSoftwareModal, setAssignSoftwareModal] = useState(false);
  const [assignSoftwareId, setAssignSoftwareId] = useState("");
  const [assignSoftware, setAssignSoftware] = useState();
  const [softwareFormData, setSoftwareFormData] = useState({
    name: "",
    assigned_to: null,
    expiry_date: "",
    version: "",
  });
  const [selectableUsers, setSelectableUsers] = useState([]);
  const [addSoftwareModal, setAddSoftwareModal] = useState(false);
  const [selectedOption, setSelectedOption] = useState([]);
  // const [usersName, setUsersName] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [usersList, setusersList] = useState([]);

  // const usersList = useSelector(state=>state.userList);
  const dispatch = useDispatch();

  // console.log('listg of users ---->',listOfUsers);
  let usersName = [];
  useEffect(async () => {
    getResponse(apipaths.listusers).then((res) => {
      setusersList(res.data.data.user);
      console.log("users useeffect hit baby", res.data.data.user);
    });
  }, []);

  usersName = usersList.map((user) => {
    return { value: user.id, label: user.name };
  });

  useEffect(async () => {
    $("#filter-inventory-wrapper").slideToggle(300);
    getResponse(apipaths.softwareInventoryList).then((res) => {
      console.log("software list --- >", res.data.data.inventorysoftware);
      setSoftwareList(res.data.data.inventorysoftware);
      setInventories(res.data.data.inventorysoftware);
    });
  }, []);

  softwareList.forEach((software, index) => {
    software.serial = index + 1;
    usersList.forEach((user) => {
      if (software["assigned_to"] === user.id) {
        software.assigned_to = [user.name, user.id, user.email];
        softwareList[index] = software;
      }
    });
  });

  useEffect(() => {
    softwareList.forEach((software) => {
      Object.keys(software).forEach(
        (k) => software[k] == null && delete software[k]
      );
    });
  }, [inventories.length]);

  const addsoftware = () => {
    setAddSoftwareModal(true);
  };

  const handleDelete = (data) => {
    getResponse({ url: `/inventory/${data.id}`, method: "DELETE" })
      .then((response) => {
        toast.success("Inventory deleted successfully");
        getResponse(apipaths.softwareInventoryList).then((res) => {
          setSoftwareList(res.data.data.inventorysoftware);
          setInventories(res.data.data.inventorysoftware);
        });
      })
      .catch((e) => toast.error("Failed to delete inventory"));
  };

  const handleEdit = (software) => {
    console.log("software---->", software);
    if (selectedOption === undefined && software.assigned_to !== null) {
      let userIndex = usersName.findIndex(
        (user) => user.value === software.assigned_to[1]
      );
      setSelectedOption(usersName[userIndex]);
    }
    setEditSoftwareModal(true);
    setEditSoftware(software);
    setSoftwareFormData(software);
    setEditSoftwareId(software.id);
  };

  console.log("edit form data", editSoftware);
  const handleFilterSearch = (text) => {
    const filteredData = softwareList.filter((item) =>
      item.name.toLowerCase().includes(text.toLowerCase().replace(/\s/g, ""))
    );
    setInventories(filteredData);
  };

  const submitNewsoftware = (e) => {
    e.preventDefault();
    setAddSoftwareModal(false);
    softwareFormData.assigned_to = softwareFormData.assigned_to.value;
    console.log("software form data", softwareFormData);
    getResponse(
      { url: "/inventory/software/add", method: "POST" },
      softwareFormData
    ).then((res) => {
      toast.success("software added successfully");
      getResponse(apipaths.softwareInventoryList).then((res) => {
        setSoftwareList(res.data.data.inventorysoftware);
        setInventories(res.data.data.inventorysoftware);
      });
    });
    setSelectedOption();
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    let filteredData = [];
    Object.values(e.target).forEach((input) => {
      if (input.name === "name" && input.value.length) {
        let data = softwareList.filter((item) =>
          item.name
            .toLowerCase()
            .includes(input.value.toLowerCase().replace(/\s/g, ""))
        );
        if (data.length) filteredData = data;
      }
      if (input.name === "model" && input.value.length) {
        let data = softwareList.filter((item) =>
          item.model.includes(input.value.toLowerCase().replace(/\s/g, ""))
        );
        if (data.length) filteredData = data;
      }
      if (input.name === "version" && input.value.length) {
        let data = softwareList.filter((item) =>
          item.version
            .toLowerCase()
            .includes(input.value.replace(/\s/g, "").toLowerCase())
        );
        if (data.length) filteredData = data;
      }
    });
    setInventories(filteredData);
  };

  const submitEditsoftware = (e) => {
    e.preventDefault();
    softwareFormData.assigned_to = softwareFormData.assigned_to.value;
    softwareFormData.id = editSoftwareId;
    softwareFormData.name =
      softwareFormData.name === undefined || softwareFormData.name === ""
        ? editSoftware.name
        : softwareFormData.name;
    softwareFormData.version =
      softwareFormData.version === undefined
        ? editSoftware.version
        : softwareFormData.version;
    softwareFormData.assigned_to = selectedOption["value"];

    console.log("edit form data--->", softwareFormData);
    getResponse(
      { url: `/inventory/software/editSoftware`, method: "PUT" },
      softwareFormData
    ).then((res) => {
      toast.success("software added successfully");
      getResponse(apipaths.softwareInventoryList).then((res) => {
        setSoftwareList(res.data.data.inventorysoftware);
        setInventories(res.data.data.inventorysoftware);
      });
    });
    setSelectedOption();
    setEditSoftwareModal(false);
    setEditSoftware({
      name: "",
      assigned_to: null,
      expiry_date: "",
      version: "",
    });
    setSoftwareFormData({
      name: "",
      assigned_to: null,
      expiry_date: "",
      version: "",
    });
  };

  const filterProps = {
    heading: "Inventory software",
    buttonOne: "Add software",
    buttonOneHandler: () => {
      addsoftware();
    },
    softwareSearch: true,
    buttonTwoHandler: () => {
      showModal();
    },
    filter: () => {
      $("#filter-inventory-wrapper").slideToggle(300);
    },
    inventories,
    handleFilterSearch,
  };

  const handleAssigningsoftware = (software) => {
    setAssignSoftwareModal(true);
    setAssignSoftware(software);
    setAssignSoftwareId(software.id);
    console.log("software--->", software);
  };

  const showModal = () => {
    setIsModalVisible(true);
  };

  const list = [
    {
      title: "Sr.No",
      field: "serial",
    },
    {
      title: "Name",
      field: "name",
    },
    {
      title: "Assigned To",
      field: "assigned_to",
      emptyValue: "-",
      render: (software) => {
        return (
          <>
            <div>{software.assigned_to[0]}</div>
            <div>{software.assigned_to[2]}</div>
          </>
        );
      },
    },
    {
      title: "Version",
      field: "version",
    },
    {
      title: "Expiry Date",
      field: "expiry_date",
    },
    {
      title: "Action",
      field: "action",
      render: (data) => {
        return (
          <div className="d-flex">
            <Tooltip title="Edit">
              <div>
                <i
                  className="fa  fa-pencil
                bg-warning ml-3 table-icon"
                  onClick={() => handleEdit(data)}
                ></i>
              </div>
            </Tooltip>
            {/* <Tooltip title="Assign">
              <div>
                <i
                  onClick={() => handleAssigningsoftware(data)}
                  className="fa  fa-tasks
                bg-primary ml-3 table-icon"
                ></i>
              </div>
            </Tooltip> */}
            <Tooltip title="Delete">
              <div>
                <i
                  onClick={() => handleDelete(data)}
                  className="fa  fa-trash
                bg-danger ml-3 table-icon"
                ></i>
              </div>
            </Tooltip>
          </div>
        );
      },
    },
  ];
  let filteredList = [];

  useEffect(() => {
    console.log("edit modal--->", editSoftwareModal);
    if (editSoftwareModal === false) {
      setEditSoftware({
        name: "",
        assigned_to: null,
        expiry_date: "",
        version: "",
      });

      setSoftwareFormData({
        name: "",
        assigned_to: null,
        expiry_date: "",
        version: "",
      });
    } else {
    }
  }, [editSoftwareModal]);

  useEffect(() => {
    console.log("truth value", JSON.stringify(formData) !== "{}");
    if (JSON.stringify(formData) !== "{}") {
      if (
        formData.name !== "" ||
        formData.model !== "" ||
        formData.version !== ""
      ) {
        disableButton(false);
      }
      if (
        formData.name === "" &&
        formData.model === "" &&
        formData.version === ""
      ) {
        disableButton(true);
      }
    }
  }, [formData]);

  return (
    <>
      <div className="hardware__inventory">
        <div>
          <FilterComponent {...{ ...filterProps }} />
        </div>
        <div className="card" id="filter-inventory-wrapper">
          <div className="card-body">
            <form
              id="filter-inventory"
              className="mb-5"
              onSubmit={handleSubmit}
            >
              <div className="row mx-auto pt-3">
                <div className="col-md-12">
                  <h4 className="fw-bold">Search software Inventory</h4>
                </div>
                <div className="col-12 col-md-6 col-lg-3 mt-3">
                  <div>
                    <div>
                      <label className="mb-2">Name</label>
                    </div>
                    <input
                      type={"text"}
                      className="form-control"
                      name="name"
                      value={formData.name}
                      onChange={(e) => {
                        setFormdata({
                          ...formData,
                          name: e.target.value,
                        });
                      }}
                    />
                  </div>
                </div>
                <div className="col-12 col-md-6 col-lg-3 mt-3">
                  <div>
                    <div>
                      <label className="mb-2">Version</label>
                    </div>
                    <input
                      name="version"
                      type="text"
                      value={formData.version}
                      className="form-control filter-input"
                      onChange={(e) => {
                        setFormdata({
                          ...formData,
                          version: e.target.value,
                        });
                      }}
                    />
                  </div>
                </div>

                <div className="col-12 mt-3 text-right">
                  <button
                    className="btn btn-radius primary__save__button"
                    type="submit"
                    disabled={isButtonDisabled}
                  >
                    Search
                  </button>
                  <button
                    className="btn primary__cancel__button ml-3"
                    onClick={() => {
                      $("#filter-inventory").trigger("reset");
                      $("#filter-inventory-wrapper").slideToggle(300);
                      let path = apipaths.softwareInventoryList;
                      path["url"] = path["url"].split("?")[0];
                      setFormdata({ name: "", model: "", version: "" });
                      setInventories(softwareList);
                    }}
                    type="button"
                  >
                    Close & Clear
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
        <div>
          <MaterialTable
            title=""
            data={inventories}
            columns={list}
            options={{
              search: false,
              paging: true,
              pageSize: 20,
              pageSizeOptions: [10, 20, 50, 100, 250],
              emptyRowsWhenPaging: false,
              exportButton: false,
            }}
          ></MaterialTable>
        </div>

        {/* Add Ticket Modal Start */}

        <Modal
          title={editSoftwareModal ? "Edit Software" : "Add Software"}
          visible={addSoftwareModal || editSoftwareModal}
          key={editSoftware}
          onCancel={() => {
            setAddSoftwareModal(false);
            setEditSoftwareModal(false);
            setSelectedOption();
          }}
          footer={null}
        >
          <form
            onSubmit={
              editSoftwareModal ? submitEditsoftware : submitNewsoftware
            }
          >
            <div className="row">
              <div className="col-lg-6 col-md-6 col-12">
                <label>
                  Name<span className="text-danger">*</span>
                </label>
                <input
                  name="name"
                  type="text"
                  className="form-control"
                  onChange={(e) => {
                    setSoftwareFormData({
                      ...softwareFormData,
                      name: e.target.value,
                    });
                  }}
                  defaultValue={editSoftware.name}
                  key={editSoftware.name}
                  required
                />
              </div>
              <div className="col-lg-6 col-md-6 col-12">
                <label>Assigned To</label>
                <Select
                  name="assigned_to"
                  options={usersName}
                  className="w-100"
                  onChange={(value) => {
                    setSelectedOption(value);
                    console.log(selectedOption);
                    setSoftwareFormData({
                      ...softwareFormData,
                      assigned_to: value,
                    });
                  }}
                  value={selectedOption}
                  defaultValue=""
                  defaultInputValue=""
                />
              </div>
            </div>
            <div className="row mt-3">
              <div className="col-lg-6 col-md-6 col-12 ">
                <label>
                  version<span className="text-danger">*</span>
                </label>
                <input
                  name="version"
                  type="text"
                  className="form-control"
                  onChange={(e) => {
                    setSoftwareFormData({
                      ...softwareFormData,
                      version: e.target.value,
                    });
                  }}
                  defaultValue={editSoftware.version}
                  key={editSoftware.version}
                  required
                />
              </div>
              <div className="col-lg-6 col-md-6 col-12 ">
                <label>
                  Expiry Date<span className="text-danger">*</span>
                </label>
                <input
                  name="expiry_date"
                  type="date"
                  className="form-control"
                  onChange={(e) => {
                    setSoftwareFormData({
                      ...softwareFormData,
                      expiry_date: e.target.value,
                    });
                  }}
                  defaultValue={editSoftware.expiry_date}
                  key={editSoftware.expiry_date}
                  required
                />
              </div>
            </div>
            <div className="col-lg-6 col-md-6 col-12">
              <div className="mt-4">
                <input type="submit" className="btn submit-btn" />
                <input
                  type="button"
                  value="Close"
                  className="btn btn-danger ml-4"
                  onClick={() => {
                    setSelectedOption();
                    setAddSoftwareModal(false);
                    setEditSoftwareModal(false);
                  }}
                />
              </div>
            </div>
          </form>
        </Modal>

        {/* Add software Modal End */}

        {/* Assign software Modal Start */}
        {/* <Modal
          title="Assign software"
          visible={assignsoftwareModal}
          footer={null}
        >
          <div className="col-12 mt-4">
            <label>software Name: {assignsoftware?.name}</label>
          </div>
          <div className="col-12 mt-4">
            <label>User</label>
            <Select
              name="assigned_to"
              options={usersName}
              className="w-100"
              onChange={(value) => {
                setSelectedOption(value);
              }}
              value={selectedOption}
              required
            />
          </div>
          <div className="col-lg-6 col-md-6 col-12">
            <div className="mt-4">
              <input type="submit" className="btn submit-btn" />
              <input
                type="button"
                value="Close"
                className="btn btn-danger ml-4"
                onClick={() => {
                  setAssignsoftwareModal(false);
                }}
              />
            </div>
          </div>
        </Modal> */}
        {/* Assign software Modal End */}
      </div>
    </>
  );
}

export default SoftwareList;
