import { apipaths } from "../api/apiPaths"
import { getResponse } from "../api/apiResponse"
import { dateFormatHandler } from "./commonAction";

const roleListAction = () => async (dispatch) => {
    const { data, statusText, status } = await getResponse(apipaths.listroles);
    if (statusText === "OK", status === 200) {
        let roles = data.data.roles;
        roles.map(role => {
            role.enable = parseInt(role.enable) === 1 ? "Yes" : "No"
            role.updated_at = dateFormatHandler(role.updated_at);
            role.created_at = dateFormatHandler(role.created_at);
        })
        dispatch({ type: "ADD_ROLES", payload: roles });
    }

}

export { roleListAction }