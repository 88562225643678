import React, { useState, useEffect } from "react";
import MaterialTable from "material-table";
import { apipaths } from "../../../api/apiPaths";
import { getResponse } from "../../../api/apiResponse";
import $ from "jquery";
import { Tooltip } from "@material-ui/core";
import { useDispatch } from "react-redux";
import FilterComponent from "../reusableComponents/filters";
import "./style.css";
import { toast } from "react-toastify";
import { Modal } from "antd";
import Select from "react-select";
import TextEditor from "../../TextEditor";

function HardwareList() {
  const [inventories, setInventories] = useState([]);
  const [isButtonDisabled, disableButton] = useState(true);
  const [selectedType, setSelectedType] = useState();
  const [hardwareList, setHardwareList] = useState([]);
  const [modal, setModal] = useState(false);
  const [editFormData, setEditFormData] = useState({});
  const [inventoryId, setInventoryId] = useState("");
  const [formData, setFormdata] = useState({
    name: "",
    model: "",
    brand: "",
    hardware_type: undefined,
  });
  const [editHardwareModal, setEditHardwareModal] = useState(false);
  const [editHardwareId, setEditHardwareId] = useState();
  const [hardwareId, setHardwareId] = useState("");
  const [editHardware, setEditHardware] = useState({});
  const [assignHardwareModal, setAssignHardwareModal] = useState(false);
  const [assignHardwareId, setAssignHardwareId] = useState("");
  const [assignHardware, setAssignHardware] = useState();
  const [hardwareFormData, setHardwareFormData] = useState({
    name: "",
    assigned_to: 0,
    model: "",
    device: "",
    prize: 0,
    description: "",
    hardware_type: "",
  });
  const [addHardwareModal, setAddHardwareModal] = useState(false);
  const [selectedUser, setSelectedUser] = useState();
  const [selectedHardware, setSelectedHardware] = useState();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [usersList, setUsersList] = useState([]);
  const dispatch = useDispatch();

  const hardwareType = [
    {
      value: "Laptop",
      label: "Laptop",
    },
    {
      value: "Monitor",
      label: "Monitor",
    },
    {
      value: "Keyboard",
      label: "Keyboard",
    },
    {
      value: "Mouse",
      label: "Mouse",
    },
    {
      value: "Headset",
      label: "Headset",
    },
    {
      value: "TV",
      label: "TV",
    },
    {
      value: "LED Display",
      label: "LED Display",
    },
    {
      value: "Switch",
      label: "Switch",
    },
    {
      value: "Printer",
      label: "Printer",
    },
    {
      value: "Webcam",
      label: "Webcam",
    },
    {
      value: "Speaker",
      label: "Speaker",
    },
    {
      value: "Other",
      label: "Other",
    },
  ];

  useEffect(() => {
    getResponse(apipaths.listusers, null).then((res) => {
      setUsersList(res.data.data.user);
    });
  }, []);

  useEffect(async () => {
    $("#filter-inventory-wrapper").slideToggle(300);
    getResponse(apipaths.hardwareInventoryList).then((res) => {
      setHardwareList(res.data.data.inventoryHardware);
      setInventories(res.data.data.inventoryHardware);
    });
  }, []);

  hardwareList.forEach((hardware, index) => {
    hardware.serial = index + 1;
    usersList.forEach((user) => {
      if (hardware["assigned_to"] === user.id) {
        hardware.assigned_to = [user.name, user.id, user.email];
        hardwareList[index] = hardware;
      }
    });
  });

  // hardwareList.forEach((hardware) => {
  //   Object.keys(hardware).forEach(
  //     (k) => hardware[k] == null && delete hardware[k]
  //   );
  // });

  // const assignToSubmitHandler = async (userId) => {
  //   if (userId) {
  //     let { data } = await getResponse(apipaths.assignTicket, {
  //       ticket_id: ticketId,
  //       assigned_to: selectedOption?.value,
  //     });
  //     setEditTicketModel(false);
  //     toast.success(data.message);
  //   }
  //   setEditTicket();
  //   setTicketId();
  // };

  const addHardware = () => {
    setAddHardwareModal(true);
    setEditHardwareModal(false);
    setEditHardware({});
  };

  const handleDelete = (data) => {
    getResponse({ url: `/inventory/${data.id}`, method: "DELETE" })
      .then((response) => {
        toast.success("Inventory deleted successfully");
        getResponse(apipaths.hardwareInventoryList).then((res) => {
          setHardwareList(res.data.data.inventoryHardware);
          setInventories(res.data.data.inventoryHardware);
        });
      })
      .catch((e) => toast.error("Failed to delete inventory"));
  };

  const handleEdit = (hardware) => {
    console.log("hardware---->", hardware);
    console.log("selectedhardware--->", selectedHardware);
    if (selectedUser === undefined && hardware.assigned_to !== null) {
      let userIndex = usersName.findIndex(
        (user) => user.value === hardware.assigned_to[1]
      );
      setSelectedUser(usersName[userIndex]);
    }
    if (hardware.assigned_to !== null) {
      if (hardware.assigned_to.length) {
        setSelectedUser({
          value: hardware.assigned_to[1],
          label: hardware.assigned_to[0],
        });
      }
    }

    if (selectedHardware === undefined && hardware.hardware_type !== null) {
      setSelectedHardware({
        value: hardware.hardware_type,
        label: hardware.hardware_type,
      });
    }

    setEditHardwareModal(true);
    setAddHardwareModal(false);
    setEditHardware({ ...hardware });
    setHardwareFormData(hardware);
    setEditHardwareId(hardware.id);
    console.log("edit hardware", editHardware);
  };

  const handleFilterSearch = (text) => {
    const filteredData = hardwareList.filter((item) =>
      item.name.toLowerCase().includes(text.toLowerCase().replace(/\s/g, ""))
    );
    setInventories(filteredData);
  };

  const submitNewHardware = (e) => {
    e.preventDefault();
    setAddHardwareModal(false);
    console.log("hardware form data", hardwareFormData);
    hardwareFormData.assigned_to =
      hardwareFormData.assigned_to !== null
        ? hardwareFormData.assigned_to[1]
        : null;
    hardwareFormData.hardware_type =
      hardwareFormData.hardware_type !== null
        ? hardwareFormData.hardware_type.value
        : null;
    getResponse(
      { url: "/inventory/hardware/add", method: "POST" },
      hardwareFormData
    ).then((res) => {
      toast.success("Hardware added successfully");
      getResponse(apipaths.hardwareInventoryList).then((res) => {
        setHardwareList(res.data.data.inventoryHardware);
        setInventories(res.data.data.inventoryHardware);
      });
    });
    setSelectedUser();
    setSelectedHardware();
  };

  const handleSubmit = (e) => {
    console.log("submit hit");
    e.preventDefault();
    let filteredData = [];
    Object.values(e.target).forEach((input) => {
      if (input.name === "name" && input.value.length) {
        let data = hardwareList.filter((item) =>
          item.name
            .toLowerCase()
            .includes(input.value.toLowerCase().replace(/\s/g, ""))
        );
        if (data.length) filteredData = data;
      }
      if (input.name === "model" && input.value.length) {
        let data = hardwareList.filter((item) =>
          item.model.includes(input.value.toLowerCase().replace(/\s/g, ""))
        );
        if (data.length) filteredData = data;
      }
      if (input.name === "brand" && input.value.length) {
        let data = hardwareList.filter((item) =>
          item.brand
            .toLowerCase()
            .includes(input.value.replace(/\s/g, "").toLowerCase())
        );
        if (data.length) filteredData = data;
      }
      if (selectedType !== undefined) {
        let data = hardwareList.filter((item) => {
          return item.hardware_type === selectedType["value"];
        });
        if (data.length) filteredData = data;
      }
    });
    setInventories(filteredData);
  };

  const submitEditHardware = (e) => {
    e.preventDefault();
    console.log("selected hardware", hardwareFormData);
    hardwareFormData.id = editHardwareId;

    hardwareFormData.name =
      hardwareFormData.name === null || hardwareFormData.name === ""
        ? editHardware.name
        : hardwareFormData.name;
    hardwareFormData.device_number =
      hardwareFormData.device_number === null
        ? editHardware.device_number
        : hardwareFormData.device_number;
    hardwareFormData.model =
      hardwareFormData.model === null || hardwareFormData.model === ""
        ? editHardware.model
        : hardwareFormData.model;
    hardwareFormData.price =
      hardwareFormData.price === null
        ? editHardware.price
        : hardwareFormData.price;
    hardwareFormData.description =
      hardwareFormData.description === null
        ? editHardware.description
        : hardwareFormData.description;
    hardwareFormData.brand =
      hardwareFormData.brand === null
        ? editHardware.brand
        : hardwareFormData.brand;
    console.group("assigned to", hardwareFormData.assigned_to);
    hardwareFormData.assigned_to =
      hardwareFormData.assigned_to !== null ? selectedUser["value"] : null;
    hardwareFormData.hardware_type =
      hardwareFormData.hardware_type !== null
        ? selectedHardware["value"]
        : null;
    getResponse(
      { url: `/inventory/hardware/editHardware`, method: "PUT" },
      hardwareFormData
    ).then((res) => {
      console.log(res);
      toast.success("Hardware added successfully");
      getResponse(apipaths.hardwareInventoryList).then((res) => {
        res.data.data.inventoryHardware.forEach((hardware, index) => {
          hardware.serial = index + 1;
          usersList.forEach((user) => {
            if (hardware["assigned_to"] === user.id) {
              hardware.assigned_to = [user.name, user.id, user.email];
              hardwareList[index] = hardware;
            }
          });
        });
        setHardwareList(res.data.data.inventoryHardware);
        setInventories(res.data.data.inventoryHardware);
      });
    });
    setEditHardware({});
    setSelectedUser();
    setSelectedHardware();
    setEditHardwareModal(false);
  };

  const filterProps = {
    heading: "Inventory Hardware",
    buttonOne: "Add Hardware",
    buttonOneHandler: () => {
      addHardware();
    },
    hardwareSearch: true,
    buttonTwoHandler: () => {
      showModal();
    },
    filter: () => {
      $("#filter-inventory-wrapper").slideToggle(300);
    },
    inventories,
    handleFilterSearch,
  };

  const handleAssigningHardware = (hardware) => {
    setAssignHardwareModal(true);
    setAssignHardware(hardware);
    setAssignHardwareId(hardware.id);
    console.log("hardware--->", hardware);
  };

  const showModal = () => {
    setIsModalVisible(true);
  };

  const list = [
    {
      title: "Sr.No",
      field: "serial",
    },
    {
      title: "Name",
      field: "name",
    },
    {
      title: "Assigned To",
      field: "assigned_to",
      emptyValue: "-",
      render: (hardware) => {
        return (
          <>
            <div>{hardware.assigned_to[0]}</div>
            <div>{hardware.assigned_to[2]}</div>
          </>
        );
      },
    },
    {
      title: "Brand",
      field: "brand",
    },
    {
      title: "Type",
      field: "hardware_type",
      emptyValue: "-",
    },
    {
      title: "Device",
      field: "device_number",
    },
    {
      title: "Model",
      field: "model",
    },
    {
      title: "price",
      field: "price",
    },
    {
      title: "Description",
      field: "description",
      emptyValue: "-",
    },
    {
      title: "Action",
      field: "action",
      render: (data) => {
        return (
          <div className="d-flex">
            <Tooltip title="Edit">
              <div>
                <i
                  className="fa  fa-pencil
                bg-warning ml-3 table-icon"
                  onClick={() => {
                    handleEdit(data);
                  }}
                ></i>
              </div>
            </Tooltip>
            {/* <Tooltip title="Assign">
              <div>
                <i
                  onClick={() => handleAssigningHardware(data)}
                  className="fa  fa-tasks
                bg-primary ml-3 table-icon"
                ></i>
              </div>
            </Tooltip> */}
            <Tooltip title="Delete">
              <div>
                <i
                  onClick={() => handleDelete(data)}
                  className="fa  fa-trash
                bg-danger ml-3 table-icon"
                ></i>
              </div>
            </Tooltip>
          </div>
        );
      },
    },
  ];
  let filteredList = [];

  useEffect(() => {
    console.log("formData--->", formData);
    if (JSON.stringify(formData) !== "{}") {
      if (
        formData.name !== "" ||
        formData.model !== "" ||
        formData.brand !== "" ||
        formData.hardware_type !== undefined
      ) {
        disableButton(false);
      }
      if (
        formData.name === "" &&
        formData.model === "" &&
        formData.brand === "" &&
        formData.hardware_type === undefined
      ) {
        disableButton(true);
      }
    }
  }, [formData]);

  let usersName = usersList.map((user) => {
    return { value: user.id, label: user.name };
  });

  return (
    <>
      <div className="hardware__inventory">
        <div>
          <FilterComponent {...{ ...filterProps }} />
        </div>
        <div className="card" id="filter-inventory-wrapper">
          <div className="card-body">
            <form
              id="filter-inventory"
              className="mb-5"
              onSubmit={handleSubmit}
            >
              <div className="row mx-auto pt-3">
                <div className="col-md-12">
                  <h4 className="fw-bold">Search Hardware Inventory</h4>
                </div>
                <div className="col-12 col-md-6 col-lg-3 mt-3">
                  <div>
                    <div>
                      <label className="mb-2">Name</label>
                    </div>
                    <input
                      type={"text"}
                      className="form-control"
                      name="name"
                      value={formData.name}
                      onChange={(e) => {
                        setFormdata({
                          ...formData,
                          name: e.target.value,
                        });
                      }}
                    />
                  </div>
                </div>
                <div className="col-12 col-md-6 col-lg-3 mt-3">
                  <div>
                    <div>
                      <label className="mb-2">Type</label>
                    </div>
                    <Select
                      menuPlacement="top"
                      name="assigned_to"
                      options={hardwareType}
                      className="w-100"
                      onChange={(value) => {
                        setSelectedType(value);
                        console.log("hardware type ", value);
                        setFormdata({
                          ...formData,
                          hardware_type: value,
                        });
                      }}
                      key={selectedType}
                      value={selectedType}
                    />
                  </div>
                </div>

                <div className="col-12 col-md-6 col-lg-3 mt-3">
                  <div>
                    <div>
                      <label className="mb-2">Model</label>
                    </div>
                    <input
                      type={"text"}
                      className="form-control"
                      name="model"
                      value={formData.model}
                      onChange={(e) => {
                        setFormdata({
                          ...formData,
                          model: e.target.value,
                        });
                      }}
                    />
                  </div>
                </div>

                <div className="col-12 col-md-6 col-lg-3 mt-3">
                  <div>
                    <div>
                      <label className="mb-2">Brand</label>
                    </div>
                    <input
                      name="brand"
                      type="text"
                      value={formData.brand}
                      className="form-control filter-input"
                      onChange={(e) => {
                        setFormdata({
                          ...formData,
                          brand: e.target.value,
                        });
                      }}
                    />
                  </div>
                </div>

                <div className="col-12 mt-5 text-right">
                  <button
                    className="btn btn-radius primary__save__button"
                    type="submit"
                    disabled={isButtonDisabled}
                  >
                    Search
                  </button>
                  <button
                    className="btn primary__cancel__button ml-3"
                    onClick={() => {
                      $("#filter-inventory").trigger("reset");
                      $("#filter-inventory-wrapper").slideToggle(300);
                      let path = apipaths.hardwareInventoryList;
                      path["url"] = path["url"].split("?")[0];
                      setFormdata({
                        name: "",
                        model: "",
                        brand: "",
                        hardware_type: undefined,
                      });
                      setSelectedType();
                      setInventories(hardwareList);
                    }}
                    type="button"
                  >
                    Close & Clear
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
        <div>
          <MaterialTable
            title=""
            data={inventories}
            columns={list}
            options={{
              search: false,
              paging: true,
              pageSize: 20,
              pageSizeOptions: [10, 20, 50, 100, 250],
              emptyRowsWhenPaging: false,
              exportButton: false,
            }}
          ></MaterialTable>
        </div>

        {/* Add Ticket Modal Start */}

        <Modal
          title={editHardwareModal ? "Edit Hardware" : "Add Hardware"}
          open={addHardwareModal || editHardwareModal}
          onCancel={() => {
            setAddHardwareModal(false);
            setEditHardwareModal(false);
            setEditHardware({});
            setHardwareFormData({});
            setSelectedUser();
            setSelectedHardware();
          }}
          footer={null}
        >
          <form
            onSubmit={
              editHardwareModal ? submitEditHardware : submitNewHardware
            }
          >
            <div className="row">
              <div className="col-lg-6 col-md-6 col-12">
                <label>
                  Name<span className="text-danger">*</span>
                </label>
                <input
                  name="name"
                  type="text"
                  className="form-control"
                  defaultValue={editHardwareModal ? editHardware.name : ""}
                  key={editHardware.name}
                  onChange={(e) => {
                    setHardwareFormData({
                      ...hardwareFormData,
                      name: e.target.value,
                    });
                  }}
                  required
                />
              </div>
              <div className="col-lg-6 col-md-6 col-12">
                <label>Assign To</label>
                <Select
                  name="assigned_to"
                  options={usersName}
                  className="w-100"
                  onChange={(value) => {
                    setSelectedUser(value);
                    setHardwareFormData({
                      ...hardwareFormData,
                      assigned_to: value,
                    });
                  }}
                  value={selectedUser}
                  key={selectedUser}
                />
              </div>
            </div>
            <div className="row mt-3">
              <div className="col-lg-6 col-md-6 col-12 ">
                <label>
                  Brand<span className="text-danger">*</span>
                </label>
                <input
                  name="brand"
                  type="text"
                  className="form-control"
                  onChange={(e) => {
                    setHardwareFormData({
                      ...hardwareFormData,
                      brand: e.target.value,
                    });
                  }}
                  required
                  defaultValue={editHardwareModal ? editHardware.brand : ""}
                  key={editHardware.brand}
                />
              </div>
              <div className="col-lg-6 col-md-6 col-12 ">
                <label>
                  Device<span className="text-danger">*</span>
                </label>
                <input
                  name="device"
                  type="text"
                  className="form-control"
                  onChange={(e) => {
                    setHardwareFormData({
                      ...hardwareFormData,
                      device_number: e.target.value,
                    });
                  }}
                  defaultValue={
                    editHardwareModal ? editHardware.device_number : ""
                  }
                  key={editHardware.device_number}
                  required
                />
              </div>
            </div>
            <div className="row mt-3">
              <div className="col-lg-6 col-md-6 col-12 ">
                <label>
                  Model<span className="text-danger">*</span>
                </label>
                <input
                  name="model"
                  type="text"
                  className="form-control"
                  onChange={(e) => {
                    setHardwareFormData({
                      ...hardwareFormData,
                      model: e.target.value,
                    });
                  }}
                  defaultValue={editHardwareModal ? editHardware.model : ""}
                  key={editHardware.model}
                  required
                />
              </div>
              <div className="col-lg-6 col-md-6 col-12 ">
                <label>
                  Price<span className="text-danger">*</span>
                </label>
                <input
                  type="number"
                  min="0"
                  className="form-control"
                  onChange={(e) => {
                    setHardwareFormData({
                      ...hardwareFormData,
                      price: e.target.value,
                    });
                  }}
                  defaultValue={editHardwareModal ? editHardware.price : ""}
                  key={editHardware.price}
                  required
                />
              </div>
            </div>
            <div className="row mt-3">
              <div className="col-lg-6 col-md-6 col-12">
                <label>Type</label>
                <Select
                  styles={{ "z-index": "7" }}
                  name="assigned_to"
                  options={hardwareType}
                  className="w-100"
                  onChange={(value) => {
                    setSelectedHardware(value);
                    console.log("hardware type ", value);
                    setHardwareFormData({
                      ...hardwareFormData,
                      hardware_type: value,
                    });
                  }}
                  key={selectedHardware}
                  value={selectedHardware}
                />
              </div>
            </div>
            <div className="row mt-3">
              <div className="col-12 mt-3">
                <label>Description</label>
                <textarea
                  rows="4"
                  className="form-control"
                  defaultValue={editHardware.description}
                  key={editHardware.description}
                  onChange={(e) => {
                    // let string = e;
                    // let div = document.createElement("div");
                    // div.innerHTML = string;
                    // let finalText = div.textContent || div.innerText || "";
                    setHardwareFormData({
                      ...hardwareFormData,
                      description: e.target.value,
                    });
                  }}
                />
              </div>
            </div>
            <div className="col-lg-6 col-md-6 col-12">
              <div className="mt-4">
                <input type="submit" className="btn submit-btn" />
                <input
                  type="button"
                  value="Close"
                  className="btn btn-danger ml-4"
                  onClick={() => {
                    setAddHardwareModal(false);
                    setEditHardwareModal(false);
                    setHardwareFormData({});
                    setEditHardware({});
                    setSelectedUser();
                    setSelectedHardware();
                  }}
                />
              </div>
            </div>
          </form>
        </Modal>

        {/* Add Hardware Modal End */}

        {/* Edit Hardware Modal Start */}
        <Modal
          title="Assign Hardware"
          visible={assignHardwareModal}
          footer={null}
        >
          <div className="col-12 mt-4">
            <label>Hardware Name: {assignHardware?.name}</label>
          </div>
          <div className="col-12 mt-4">
            <label>Assign To</label>
            <Select
              name="assigned_to"
              options={usersName}
              className="w-100"
              onChange={(value) => {
                setSelectedUser(value);
              }}
              value={selectedUser}
              required
            />
          </div>
          <div className="col-lg-6 col-md-6 col-12">
            <div className="mt-4">
              <input type="submit" className="btn submit-btn" />
              <input
                type="button"
                value="Close"
                className="btn btn-danger ml-4"
                onClick={() => {
                  setAssignHardwareModal(false);
                }}
              />
            </div>
          </div>
        </Modal>
        {/* Edit Hardware Modal End */}
      </div>
    </>
  );
}

export default HardwareList;
