import React from "react";
import { Collapse } from "antd";
import { List } from "antd";
import { dateHandler } from "../../../actions/commonAction";

function TicketInformation(props) {
  const { Panel } = Collapse;
  const { ticket, closeTicketHandler, reopenTicketHandler } = props;

  console.log("status--->", ticket.status);


  return (
    <Collapse key={ticket} defaultActiveKey={["1"]}>
      <Panel header="Ticket Information" key="1">
        <List
          bordered
          footer={
            <div>
              {ticket.status === "closed" ? (
                <button
                  key={ticket.status}
                  className="btn btn-warning w-100"
                  onClick={() => reopenTicketHandler(ticket)}
                >
                  Reopen
                </button>
              ) : (
                <button
                  className="btn btn-danger w-100"
                  onClick={() => closeTicketHandler(ticket)}
                >
                  Close
                </button>
              )}
            </div>
          }
        >
          <List.Item>
            <div>
              <h4 className="mb-0">Requestor</h4>
              <p className="mb-0">{ticket.created_by}</p>
            </div>
          </List.Item>
          <List.Item>
            <div>
              <h4 className="mb-0">Status</h4>
              <p className="mb-0 text-capitalize">{ticket.status}</p>
            </div>
          </List.Item>
          <List.Item>
            <div>
              <h4 className="mb-0">Last Updated</h4>
              <p className="mb-0">{dateHandler(ticket.updated_at)}</p>
            </div>
          </List.Item>
        </List>
      </Panel>
    </Collapse>
  );
}

export default TicketInformation;
