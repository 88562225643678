import React, { useEffect, useState, useRef } from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { apipaths } from "../../api/apiPaths";
import { getResponse } from "../../api/apiResponse";

import temboLogo from "../assets/TEMBO SAKETH & RAWLPLUG LOGO CONVERTED FILE (1)-1.png"

import './style.css'
const queryString = require("query-string");

function RaiseTicket(props) {
    const childRef = useRef(null)

    const [email, setEmail] = useState("");
    const [subject, setSubject] = useState("");
    const [description, setDescription] = useState("");
    const [formdata, setFormdata] = useState({});



    const dispatch = useDispatch();
    //   const loginHandler = async (e) => {
    //     e.preventDefault && e.preventDefault();
    //     setError({ show: false });
    //     let { status_code, data, error } = await getResponse(apipaths.login, {
    //       email: email ? email : e.email,
    //       password: password ? password : e.password,
    //     });

    //     if (error) {
    //       return setError({
    //         show: true,
    //         message: "Incorrect credentials entered.",
    //       });
    //     }
    //     dispatch(addUserDetailsAction(data?.data));
    //     if (data.data.userType === "Co-Admin") {
    //       props.history.push("/tickets");
    //     } else if (data.data.userType === "User") {
    //       props.history.push("/userdashboard");
    //     } else {
    //       props.history.push("/dashboard");
    //     }
    //   };



    //   const createUserHandler = async (e) => {
    //     e.preventDefault();
    //     setError({ show: false, message: "" });
    //     if (formdata.password === formdata.repassword) {
    //       const res = await getResponse(apipaths.resetPassword, {
    //         password: formdata.password,
    //         token: parsed.token,
    //         email: parsed.email,
    //         userType: "admin"
    //       })
    //       props.history.push("/")
    //     } else {
    //       setError({
    //         show: true,
    //         message: "Password and confirm password do not match. ",
    //       });
    //     }
    //   };







    return (
        <>
            <div className="login-page content-wrapper">
                
                <div className="row mx-auto g-0">
                    <div className="row ">
                        <div className="col-lg-12">
                            
                        </div>
                    </div>
                    <div className="col-lg-3">
                        <div className="logoContainer">
                            <img
                                src={temboLogo}
                                alt="logo"
                                className="img-fluid mb-4 d-block  compliance-logo"></img>
                            {/* <img
                  src={sciencelogo}
                  alt="logo"
                  className="img-fluid mb-4 d-block  compliance-logo"></img>
                  <img
                  src={lifescience}
                  alt="logo"
                  className="img-fluid mb-4 d-block  compliance-logo"></img> */}

                        </div>
                    </div>
                    <div className="col-lg-6  pt-5">
                        <div className="form-container">

                            <div className=" auth-content">
                                <h1 className="mb-4 f-w-400 bold sign-in-head">Welcome to Ticketing System</h1>
                                <span className='subHead'>Add details to create ticket.</span>



                                <form >


                            
                                    <div className="form-group mb-3">
                                        <label className="input-label" htmlFor="Email">
                                            Email Address
                                        </label>
                                        <input
                                            type="text"
                                            className="form-controls input-box"
                                            onChange={(e) => setEmail(e.target.value)}
                                            name="email"
                                            id="Email"
                                            placeholder=""
                                            value={email}
                                            required=""
                                        />
                                    </div>
                                    <div className="form-group mb-4 position-relative">
                                        <label className="input-label" htmlFor="Subject">
                                            Subject
                                        </label>
                                        <input

                                            className="form-controls input-box"
                                            onChange={(e) => setSubject(e.target.value)}
                                            name="subject"
                                            value={subject}
                                            id="Subject"
                                            placeholder=""
                                            required=""
                                        />
                                        <div>
                                            {/*
                          passType === "text" ? (
                            <i className="fas fa-eye show-password" onClick={() => setPassType("password")}></i>
                          ) : (
                            <i className="fas fa-eye-slash show-password" onClick={() => setPassType("text")}></i>
                          )
                          */}

                                        </div>
                                    </div>
                                    <div className="form-group mb-4 position-relative">
                                        <label className="input-label" htmlFor="Subject">
                                            Description
                                        </label>
                                        <input

                                            className="form-controls input-box"
                                            onChange={(e) => setDescription(e.target.value)}
                                            name="description"
                                            value={subject}
                                            id="Description"
                                            placeholder=""
                                            required=""
                                        />
                                        <div>
                               

                                        </div>
                                    </div>
                                  
                                    <div className='btnWrap'>
                                        <button
                                            type="submit"
                                            className="btn btn-block text-center sign-in-button mb-4"
                                        >
                                            Submit
                                        </button>
                                    </div>
                                </form>


                            </div>
                        </div>
                    </div>
                    <div className="col-lg-3"></div>

                </div>
            </div>
        </>
    );
}

export default RaiseTicket;
