import React, { useEffect, useState } from "react";
import InputFeild from "../InputFeild";

function RoleForm(props) {

    const { submitHandler, setModal, role } = props;
    const [roleName, setRoleName] = useState('');

    useEffect(() => {
        setRoleName(role?.name);
    },[role])

    return (
        <form onSubmit={(e) => { e.preventDefault(); submitHandler({ ...role, name: roleName}) }}>
            <div className="row">
                <div className="col-12 mt-3">
                    <InputFeild
                        type="text"
                        label={<label>Role Name<span className="text-danger">*</span></label>}
                        value={roleName}
                        onChange={(e) => setRoleName(e.target.value)}
                    />
                </div>
                <div className="col-12 mt-3">
                    <div className="pull-right">
                        <button className="btn btn-success" type="submit">Submit</button>
                        <button className="btn btn-danger ml-4" type="button" onClick={() => { setModal(false) }}>Close</button>
                    </div>
                </div>
            </div>

        </form>
    )

}

export default RoleForm;