import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { inventoryListAction } from "../../../actions/inventoryAction";
import InputFeild from "../../forms/InputFeild";
import { getResponse } from "../../../api/apiResponse";
import { apipaths } from "../../../api/apiPaths";
import moment from "moment";
import { toast } from "react-toastify";
import Select from 'react-select'
import { dateFormatYYMMDD } from "../../../actions/commonAction"

function AddInventory(props) {
    const dispatch = useDispatch();
    const {
        isOpen,
        inventoryId,
        editFormData = {},
        type,
        editForm,
    } = props;
    const [formdata, setFormdata] = useState({});
    const [users, setUsers] = useState([]);
    const [selectedOption, setSelectedOption] = useState([]);
    const [selectedInventoryTypeOption, setSelectedInventoryTypeOption] = useState([]);
    const [selectedInventoryOption,setSelectedInventoryOption]=useState([])
    const inventoryType = [
        {
            value: 'hardware',
            label: 'Hardware'
        },
        {
            value: 'software',
            label: 'Software'
        }

    ];
    const inventories = [
        {
            value: 'Laptop',
            label: 'Laptop'
        },
        {
            value: 'Monitor',
            label: 'Monitor'
        },
        {
            value: 'Keyboard',
            label: 'Keyboard'
        },
        {
            value: 'Mouse',
            label: 'Mouse'
        },
        {
            value: 'Headset',
            label: 'Headset'
        },
        {
            value: 'TV',
            label: 'TV'
        },
        {
            value: 'LED Display',
            label: 'LED Display'
        },
        {
            value: 'Switch',
            label: 'Switch'
        },
        {
            value: 'Printer',
            label: 'Printer'
        },
        {
            value: 'Webcam',
            label: 'Webcam'
        },
        {
            value: 'Speaker',
            label: 'Speaker'
        },
        {
            value: 'Other',
            label: 'Other'
        },
    ];

    useEffect(() => {
        userlist();
    }, []);

    useEffect(() => {
        if (type === 'hardware') {
            setFormdata({
                ...editFormData,
                warranty_expire_on: editFormData.warranty_expire_on !== undefined ? dateFormatYYMMDD(editFormData.warranty_expire_on) : '',
                assigned_on: editFormData.assigned_on !== undefined ? dateFormatYYMMDD(editFormData.assigned_on) : '',
            });
        }
        else {
            setFormdata({
                ...editFormData,
                expiry_date: editFormData.expiry_date !== undefined ? dateFormatYYMMDD(editFormData.expiry_date) : '',
            });
        }

        if (editFormData.user !== undefined && editFormData.user !== null) {
            setSelectedOption({
                value: editFormData.user.id,
                label: editFormData.user.name
            });
        }
        if (editFormData.hardware_type !== undefined && editFormData.hardware_type !== null) {
            // setSelectedHardwareOption({
            //     value: editFormData.hardware_type,
            //     label: editFormData.hardware_type
            // });
        }
    }, [editFormData]);

    const userlist = async () => {
        const { data } = await getResponse(apipaths.usergetlist);
        let userOptions = [{
            label: 'none', value: 0
        }];
        data.data?.user.map((user) => (
            userOptions.push({
                value: user.user_details?.id,
                label: user.user_details?.firstName
            })

        ))
        setUsers(userOptions);

    };

    const submitHandlerSoftware = async (formdata) => {
        let data = formdata;
        if (inventoryId) {
            data.assigned_to = selectedOption?.value

        }
        else {
            data.assigned_to = 'none'
            data.status = 'Available'
        }
        return
        const { name } = data;
        if (!name) {
            return toast.warn("Name is mandatory.");

            //return toast.warn("All * fields are mandatory.");
        }

        if (inventoryId) {
            data.operation = "update";
            data.assigned_on = editFormData.assigned_on;
            data.id = inventoryId;
        } else data.operation = "add";

        let resp = await getResponse(apipaths.addInventorySoftware, data);

        if (resp.status === 200) {
            toast.success(resp.data.message);
            setSelectedOption([]);
            setFormdata({});
            dispatch(inventoryListAction(type));
            isOpen(false);
        } else {
            toast.error(resp.error.message);
        }

    };

    const locations = ['USA', 'Costa Rica', 'India']

    const submitHandlerHardware = async (formdata) => {
        let data = formdata;
        data.type = "Hardware";

        const {
            // brand,
            asset_name,
            unit_price,
            // device_number,
            service_tag,
            model,
            express_service_code,
            // device_name,
            //assigned_to,
            location,
            description,
            warranty_expire_on,
        } = data;

        //data.assigned_to = selectedOption?.value
       // data.hardware_type = selectedHardwareOption?.value
        data.status = 'Available'

        if (
            !asset_name ||
            !unit_price ||
            !service_tag ||
            !model ||
            !express_service_code ||

           // !selectedHardwareOption?.value ||
            !warranty_expire_on ||
            !location
        ) {
            toast.warn("All * feilds are mandatory.");
        } else {
            if (inventoryId) {
                data.operation = "update";
                data.id = inventoryId;
            } else data.operation = "add";

            let resp = await getResponse(apipaths.addInventoryHardware, data);

            if (resp.status === 200) {
                toast.success(resp.data.message);
                setFormdata({});
                setSelectedOption([]);
                //setSelectedHardwareOption([]);
                dispatch(inventoryListAction(type));
                isOpen(false);
            } else {
                toast.error(resp.error.message);
            }
        }
    };

    return (
        <>
            <form
                onSubmit={(e) => {
                    e.preventDefault();
                    if (type == "software") {
                        submitHandlerSoftware(formdata);
                    }
                    else {
                        submitHandlerHardware(formdata);
                    }
                }}
            >
              
                    <div className="row">
                        <div className="col-lg-6 col-md-6 col-12 mt-3">
                            <InputFeild
                                label="Asset Name"
                                mandatory={true}
                                value={formdata.asset_name ? formdata.asset_name : ''}
                                onChange={(e) =>
                                    setFormdata({ ...formdata, asset_name: e.target.value })
                                }
                                disabled={editForm ? "disabled" : ""}
                            />
                        </div>

                        <div className="col-lg-6 col-md-6 col-12 mt-3">
                            <InputFeild
                                label="Units"
                                type="number"
                                mandatory={true}
                                value={formdata.units ? formdata.units : ''}
                                onChange={(e) =>
                                    setFormdata({ ...formdata, units: e.target.value })
                                }
                                disabled={editForm ? "disabled" : ""}
                            />
                        </div>
                        <div className="col-lg-6 col-md-6 col-12 mt-3">
                            <label>Type<span className="text-danger"> * </span></label>

                            <Select
                                name="inventory_type"
                                options={inventoryType}
                                className="w-100"
                                onChange={(value) => {
                                    setSelectedInventoryTypeOption(value);
                                }}
                                value={selectedInventoryTypeOption}
                                isDisabled={editForm ? true : false}
                                required
                            />
                        </div>
                        <div className="col-lg-6 col-md-6 col-12 mt-3">
                            <label>Inventory<span className="text-danger"> * </span></label>

                            <Select
                                name="inventories"
                                options={inventories}
                                className="w-100"
                                onChange={(value) => {
                                    setSelectedInventoryOption(value);
                                }}
                                value={selectedInventoryOption}
                                isDisabled={editForm ? true : false}
                                required
                            />
                        </div>
                        

                        <div className=" col-12 mt-3 pull-right text-right ">
                            {editForm == 0 ? (
                                <button className="btn submit-btn">
                                    {inventoryId ? "Update" : "Add"}
                                </button>
                            ) : (
                                ""
                            )}
                            {/* <button
                type="button"
                className="btn btn-danger ml-3"
                onClick={() => {isOpen(false)}}
                // setFormdata({});
              >
                Close
              </button> */}
                        </div>
                    </div>
             
            </form>
        </>
    );
}

export default AddInventory;
