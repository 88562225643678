
import logo from "../../assets/logo1.png"
import React from 'react'
import temboLogo from "../../assets/TEMBO SAKETH & RAWLPLUG LOGO CONVERTED FILE (1)-1.png"
import temboLogo2 from "../../assets/TEMBO SAKETH & RAWLPLUG LOGO 2-1.png"
function Header() {
    return (
        <>
            <div className="header__section"> 
                 
            </div>
        </>
    );
}

export default React.memo(Header);